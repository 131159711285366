import CompanyValue from './component/company-value';
import styles from './company-value.module.scss';
import globalImage from '@/public/global.png';
import marranitoImg from '@/public/ping-savings.png';
import moneyImage from '@/public/savings.png';
import PTText from '../common/text/pt-text';

const keyDataItems = [
  'Podrás agendar la reunión con el consultor experto en precios de transferencia en bloques de mínimo una hora. Ten en cuenta que el espacio agendado será lo que se te descontará de tu tiempo disponible, sin importar si la reunión toma menos tiempo, por ejemplo; si programas una reunión de una hora pero solo te gastas 20 minutos, se te descontará la hora completa. Te recomendamos tener toda la información disponible y precisa para poder aprovechar al máximo la sesión programada. Ten presente que podrás cancelar o reprogramar la reunión con el consultor hasta dos horas antes del inicio de esta para que no se te descuente el tiempo.',
  'Si requieres más tiempo con el consultor experto en Precios de Transferencia, siempre podrás adquirir más horas por un valor de $175.000/hora.',
  'Ten en cuenta que en estas sesiones solo se resolverán temas relacionados con el diligenciamiento de la Declaración de Precios de Transferencia. Por ejemplo, no se resolverán dudas sobre planeaciones de Precios de Transferencia, fiscalizaciones, o temas especializados o de mayor complejidad ni tampoco se prestará la asesoría para la presentación de la Declaración ante la DIAN, esto es un servicio adicional que tenemos disponible para ti.',
  'Las horas incluidas en el plan que compres solo las podrás usar para el año gravable para el cual adquiriste el plan.'
];


export default function CompanyValues() {
  const companyValues = [
    {
      image: globalImage,
      title: 'Seguro',
      items: [
        'Garantizamos que la Declaración de Precios de Transferencia de tu Compañía cumpla con los requisitos exigidos por la ley.',
        'La información que proporcionas está protegida con los más altos estándares de seguridad y está cubierta por una política de privacidad <a href="/terminos-y-condiciones" style="color: white">(Consultar TERMINOS Y CONDICIONES)</a>.',
      ],
    },
    {
      image: marranitoImg,
      title: 'Sin obstáculos',
      items: [
        'Siempre tendrás a tu disposición acompañamiento personalizado cuando lo requieras.',
        'Sin filtros, sin aprobación de propuestas, sin demoras administrativas para acceder a nuestros servicios, con solo dar un par de clics lo podrás hacer.',
        'Lo hacemos por ti, por lo cual no requieres conocimientos de precios de transferencia, nuestro sistema integral te guiará durante todo el proceso.',
      ],
    },
    {
      image: moneyImage,
      title: 'Precios justos',
      items: ['Los precios más competitivos del mercado.'],
    },
  ];

  return (
    <div className={`${styles.componentDeclaration} ${styles.section}`}>
      <div className={styles.sectionIntro}>
        <PTText asTag="h2" size="xxxl" weight="700" className={styles.heading}>
          TPTRIP tu mejor opción para preparar la Declaración de Precios de
          Transferencia
        </PTText>
      </div>
      <div className={styles.column4}>
        {companyValues.map((companyValue) => (
          <CompanyValue companyValue={companyValue} key={companyValue.title} />
        ))}
      </div>
    </div>
  );
}
