import PTButton from '@/components/common/button/pt-button';
import YouTube from 'react-youtube';
import PTText from '../common/text/pt-text';
import styles from './main-banner.module.scss';
import { useRouter } from 'next/router';

export default function MainBanner() {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.containerVideoAndTitle}>
        <PTText asTag='h2' size="xxl" weight="700" className={styles.header}>
          Preparación de la Declaración de Precios de Transferencia de manera
          confiable, ágil y sin complicaciones
        </PTText>
        <YouTube
          className={styles.containerYoutube}
          videoId="w8eNSb1QfF0"
          opts={{
            height: '330',
            width: '550',
            playerVars: {
              autoplay: false,
            },
          }}
        />
      </div>

      <div className={styles.containerTextBlock}>
        <div>

          <PTText size="lg" weight="400" className={styles.textBlock}>
            <i className="pi pi-angle-double-right" style={{ marginRight: '3px' }} />
            Te ayudamos con todo el proceso de elaboración y presentación de la
            Declaración de Precios de Transferencia.
          </PTText>
        </div>
        <br />
        <PTText size="lg" weight="500" className={styles.textBlock}>
          ¡Tú eliges el nivel de acompañamiento!
        </PTText>
      </div>

      <div className={styles.containerButton}>
        <PTButton
          size="lg"
          isMain={false}
          onClick={() => router.push('/planes')}>
          Elaborar la Declaración de Precios de Transferencia
        </PTButton>
      </div>
    </div>
  );
}
