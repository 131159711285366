import styles from './main-services-actions.module.scss';
import ServicesActionComponent from './component/main-service-action';
import { useRouter } from 'next/router';
import PTText from '../common/text/pt-text';
import LineDivider from '../common/line-divider/line-divider';
import { confirmDialog } from 'primereact/confirmdialog';
import { useAppSelector } from '@/redux/store';

export default function MainServicesAction() {
  const router = useRouter();
  const { user } = useAppSelector((state) => state.session);

  const handleShouldDeclare = () => {
    router.push('/verifica');
  };

  const handleDateDeclare = () => {
    router.push('/declaracion-informativa-precios-de-transferencia');
  };

  const showAdminAlert = () => {
    confirmDialog({
      header: '¡Importante!',
      message: `Esta función es únicamente para no administradores.`,
      style: { width: '45%' },
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cerrar'
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <PTText
          asTag="h2"
          size="xxxl"
          weight="700"
          withQuestionMark
          className={styles.header}
        >
          Cuándo y qué debo presentar en materia de precios de transferencia
        </PTText>
      </div>

      <div className={styles.featureCards}>
        <ServicesActionComponent
          titleBox="Verifica si tu compañía está sujeta al régimen de Precios de Transferencia"
          textButton="¡Verifica!"
          onClick={
            user?.role !== 'admin' ? handleShouldDeclare : showAdminAlert
          }
        />

        <ServicesActionComponent
          titleBox="Revisa los plazos en los cuales se deben presentar las obligaciones de Precios de Transferencia de tu Compañia"
          textButton="¡Revisa!"
          onClick={user?.role !== 'admin' ? handleDateDeclare : showAdminAlert}
        />
      </div>
      <LineDivider />
    </div>
  );
}
